import React, { Component } from 'react';
import Firebase from './api/Firebase';
import Loading from './loading.gif';
import * as Icon from 'react-feather';
import './App.css';

Firebase.firestore().settings({ timestampsInSnapshots: true });

class App extends Component {

  constructor() {
    super();

    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    const metaId = params.get('id');
    const preview = params.get('preview');

    this.state = {
      doc: null,
      fields: null,
      fullName: null,
      formLocked: false,
      formSubmitting: false,
      formSubmitted: false,
      metaId: metaId,
      preview: preview,
      user: null,
    }

    this.formRef = React.createRef();
    this.fetchData = this.fetchData.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.signIn = this.signIn.bind(this);
    this.changeFullName = this.changeFullName.bind(this);
  }

  componentDidMount() {
    this.fetchData();

    Firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        this.setState({
          user: user
        });

        this.fetchData();
      }
    });
  }

  fetchData() {
    const metaPromise = Firebase.firestore().collection('meta').doc(this.state.metaId).get();

    metaPromise.then((docs) => {
      return docs;
    })
    .then((docs) => {
      Firebase.firestore().collection('templates').doc(docs.data().templateId).get()
      .then((templateDocs) => {
        this.setState({
          doc: docs,
          fullName: docs.data().fullName,
          fields: templateDocs.data().fields
        });
      })
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        formLocked: true,
      });
      if(this.state.preview === 'true') {
        this.setState({
          doc: {},
          fields: {},
        });
      }
    });
  }

  changeFullName(e) {
    this.setState({
      fullName: e.target.value
    })
  }

  submitForm(e) {
    e.preventDefault();

    if(this.state.preview === 'true') {
      alert('Formulier kan niet worden verzonden in preview mode.');
      return false;
    }

    const formControls = this.formRef.current.querySelectorAll('[name]');
    
    // 1 validate
    let submittedData = [];

    formControls.forEach((element, index) => {
      submittedData.push({
        value: element.value,
        name: element.name,
        label: element.dataset.label
      });
    });

    // 2 save submittedData & update dateUpdated & meta:locked
    Firebase.firestore().collection('meta').doc(this.state.metaId).update({
      fullName: this.state.fullName,
      submittedData: submittedData
    })
    .then(() => {
      // 3 remove submittedData
      this.setState({
        formSubmitted: true
      });
    });
  }

  signIn(e) {
    e.preventDefault();

    this.setState({
      doc: null,
      fields: null,
      formSubmitting: true
    });

    Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
    .catch((error) => {
      alert(error.message);
      this.setState({
        formSubmitting: false,
      });
    });
  }

  setEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  setPassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  render() {

    if(this.state.formLocked && !this.state.preview) {
      return <div className="container plain tac"><div className="App"><p><Icon.CheckCircle size={60} color="#6CEBB1"/></p> Het formulier is reeds verzonden.</div></div>;
    }

    if(!this.state.fields && !this.state.doc) {
      return <div className="container plain tac"><div className="App"><img src={Loading} width={42} alt=""/></div></div>;
    }

    if(this.state.preview === 'true' && !this.state.user) {
      return (
        <div className="container">
          <div className="App">
            <p className="lead">Login om preview te bekijken.</p>
            <form onSubmit={this.signIn}>
              <div>
                <label>
                  <input className="form-control" required={true} name="email" placeholder="E-mail" onChange={this.setEmail.bind(this)} type="email"/>
                </label>
              </div>
              <div>
                <label>
                  <input className="form-control" required={true} name="password" placeholder="Wachtwoord" onChange={this.setPassword.bind(this)} type="password"/>
                </label>
              </div>
              <br/>
              <button disabled={this.state.formSubmitting} type="submit">{this.state.formSubmitting ? 'Formulier verzenden... moment aub.' : 'Verzenden'}</button>
            </form>
          </div>
        </div>
      );
    }

    if(this.state.formSubmitted) {
      return <div className="container plain tac"><div className="App"><p><Icon.CheckCircle size={60} color="#6CEBB1"/></p>Bedankt! Het formulier is verzonden.</div></div>;
    }

    const doc = this.state.doc.data();

    const fields = this.state.fields.map((field, index) => {

      let fieldElement = '';
      
      if(field.type === 'text' || field.type === 'checkbox' || field.type === 'date' || field.type === 'number' || field.type === 'email') {
        fieldElement = <input data-label={field.label} required={field.required} className="form-control" type={field.type} name={field.name}/>
      }

      if(field.type === 'textarea') {
        fieldElement = <textarea data-label={field.label} required={field.required} className="form-control" rows={5} name={field.name}></textarea>
      }

      if(field.type === 'select') {
        fieldElement = (
          <select data-label={field.label} required={field.required} className="form-control" name={field.name}>
            <option value="">--- Maak keuze ---</option>
            {field.options.map((option, index) => {
              return <option key={index}>{option}</option>
            })}
          </select>
        )
      }

      return(
        <div className="formRow" key={index}>
          <label>
            {field.label} {field.required && <span className="required">*</span>}
            {fieldElement}
          </label>
        </div>
      )
    });

    return (
      <div className="container">
        <header className="header">
          <img alt="" height="71.5" src="//brendaoliefotografie.nl/wp-content/uploads/brendaoliefotografie.png"/>
        </header>
        <div className="App">
          <p className="lead well">Vul hier alle gegevens in om de shoot definitief in te boeken.</p>
          <form onSubmit={this.submitForm} ref={this.formRef}>
            <div className="formRow">
              <label>
                Naam
                <input onChange={this.changeFullName} defaultValue={doc.fullName} type="text" required={true} className="form-control"/>
              </label>
            </div>
            <div className="formRow hidden">
              <label>
                E-mail
                <input data-label="E-mail" defaultValue={doc.email} disabled={true} type="email" className="form-control"/>
              </label>
            </div>
            {fields}
            <p><small className="required">* verplichte velden</small></p>
            <button disabled={this.state.formSubmitting} type="submit">{this.state.formSubmitting ? 'Formulier verzenden... moment aub.' : 'Verzenden'}</button>
          </form>
        </div>
      </div>
    );
  }
}

export default App;
